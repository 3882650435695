import React from "react"

import Layout from "../components/layout"
import Seo from "../components/seo"

const ContactPage = () => (
  <Layout>
    <Seo title="Contact" />
    <div className="row">
      <div className="col-md-6 offset-md-3 text-center mb-5">
        <h1>Contact</h1>

        <hr />
        <p className="lead">
          Contactez-nous pour toutes questions à propos de nos produits ou tout
          autre renseignement supplémentaire
        </p>
        <hr />
        <p>
          Vapor Flavor
          <br />
          Rte du Petit Bois 5
          <br />
          1727 Corpataux
        </p>
        <p>
          <strong>Téléphone</strong>
          <br />
          <a href="tel:0041 79 222 87 34">079 222 87 34</a>
        </p>
        <p>
          <strong>Email</strong>
          <br />
          t.wyss@baselab.ch
        </p>
      </div>
    </div>
  </Layout>
)

export default ContactPage
